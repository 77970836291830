import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from '@reach/router';

import { useResetScroll } from '../helpers/useResetScroll';
import './Submit.css';

interface Props extends RouteComponentProps {}

const Submit: FunctionComponent<Props> = () => {
	useResetScroll();
	return (
		<article className="Submit">
			<h1>Submit Your Spock</h1>
			<p>We will be taking Spock submissions soon. Check back!</p>
		</article>
	);
};

export default Submit;
