import React, { FunctionComponent } from 'react';
import { RouteComponentProps, Router } from '@reach/router';

import { LocationState } from '../helpers/navigation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NotFound from '../components/NotFound';
import Rate from './Rate';
import Submit from './Submit';
import Materials from './Materials';
import Disclaimer from './Disclaimer';
import RouteNotFound from './RouteNotFound';
import './AppRoot.css';

interface Props extends RouteComponentProps {}

const AppRoot: FunctionComponent<Props> = ({ location }) => {
	const notFound = (location?.state as LocationState)?.notFound ?? false;
	return (
		<div className="AppRoot">
			<Header />
			<main>
				{notFound ? (
					<NotFound />
				) : (
					<Router>
						<Rate path="/" />
						<Rate path="/spocks/:spockId" />
						<Submit path="/submit-your-spock" />
						<Materials path="/spock-materials" />
						<Disclaimer path="/disclaimer" />
						<RouteNotFound default />
					</Router>
				)}
			</main>
			<Footer />
		</div>
	);
};

export default AppRoot;
