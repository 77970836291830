import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from '@reach/router';

import { useResetScroll } from '../helpers/useResetScroll';
import './Materials.css';

interface Props extends RouteComponentProps {}

const Materials: FunctionComponent<Props> = () => {
	useResetScroll();
	return (
		<article className="Materials">
			<h1>Spock Materials</h1>
			<p>
				If you are still thirsting for Spock-related material,
				check out these books:
			</p>
			<ul>
				<li>
					<a href="https://en.wikipedia.org/wiki/I_Am_Not_Spock">
						<i>I Am Not Spock</i> by Leonard Nimoy
					</a>
				</li>
				<li>
					<a href="https://en.wikipedia.org/wiki/I_Am_Spock">
						<i>I Am Spock</i> by Leonard Nimoy
					</a>
				</li>
			</ul>
		</article>
	);
};

export default Materials;
