const spocks = [
	'03f88899',
	'07cae4b4',
	'0f024c35',
	'0f730f8c',
	'0fed41e7',
	'10bb4bc7',
	'1174bd09',
	'11cc057c',
	'121e30ea',
	'122f2aab',
	'1794bbb9',
	'20aadfcb',
	'21d627d9',
	'299b78c5',
	'29f98560',
	'2ab07f07',
	'2ae08ecf',
	'2e689e5f',
	'2ec63c90',
	'2f5a4a21',
	'2fb0746b',
	'30432174',
	'328a7868',
	'3768be1c',
	'3a1b6223',
	'3c4f8e4f',
	'3d3e316a',
	'4299e096',
	'452b31c9',
	'472cd800',
	'4f161531',
	'507e2a15',
	'5248cc96',
	'5c781979',
	'5f10ee2e',
	'65bd9f59',
	'6633d894',
	'68950341',
	'6d0e295d',
	'6ef2af37',
	'6fdc2e12',
	'7293cc4f',
	'74d9eecb',
	'77add1ef',
	'783c1f61',
	'796c6ecf',
	'7d8c4b7e',
	'7dc6804f',
	'81ceb17e',
	'81f38b43',
	'825af268',
	'82814eb8',
	'84961d2e',
	'86a04724',
	'8ae10403',
	'8af21883',
	'99d4077a',
	'9aa67770',
	'9fb96571',
	'a092a317',
	'a3795e3b',
	'a4c6f90e',
	'a57f02ff',
	'ad1091bf',
	'ae1919da',
	'ae81de53',
	'b245a16f',
	'b7782030',
	'b7fd8dee',
	'b9d6b9e2',
	'bb38d064',
	'c1bee342',
	'c2b27e67',
	'c60b7808',
	'c8c40859',
	'c95739de',
	'c957924d',
	'cd082ace',
	'd13ba7b6',
	'd1ad2d52',
	'd3dceaab',
	'd4b2704f',
	'd6ca0d6f',
	'd94bf36b',
	'dbdc8740',
	'dbf4b1c3',
	'dccddd15',
	'de425d75',
	'e3f98101',
	'e46d0ff0',
	'e690af54',
	'e6be737a',
	'e748f352',
	'f17b4cd8',
	'f374cd52',
	'f77b62c8',
	'fc936925',
	'fe1f9acb',
	'fe66777d',
];

export default spocks;
