import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from '@reach/router';

import { useResetScroll } from '../helpers/useResetScroll';
import './Disclaimer.css';

interface Props extends RouteComponentProps {}

const Disclaimer: FunctionComponent<Props> = () => {
	useResetScroll();
	return (
		<article className="Disclaimer">
			<h1>Fair Use Notice &amp; Disclaimer</h1>
			<p>
				This website was originally created in 2003 for the
				purposes of parody and social criticism, for non-commercial
				use, and is in no way affiliated with the intellectual
				property holders of the character Spock nor the
				now-defunct HotOrNot rating website.
			</p>
			<p>
				This website may contain copyrighted material the use
				of which has not been specifically authorized by its
				copyright owners. We believe this constitutes a fair use of
				any such copyrighted material as provided for in section 107
				of the U.S. Copyright Act.
			</p>
			<p>
				Rest in peace, Leonard Nimoy. We miss you.
			</p>
		</article>
	);
};

export default Disclaimer;
