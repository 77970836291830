import React from 'react';
import { Link } from '@reach/router';

import './Footer.css';

const Footer = () => (
	<footer className="Footer">
		<nav className="mini">
			<Link to="/">Rate</Link>
			<Link to="/submit-your-spock">Submit</Link>
			<Link to="/spock-materials">Materials</Link>
			<Link to="/disclaimer">Disclaimer</Link>
		</nav>
		<nav className="full">
			<Link to="/">Rate Spocks</Link>
			<Link to="/submit-your-spock">Submit Your Spock</Link>
			<Link to="/spock-materials">Spock Materials</Link>
			<Link to="/disclaimer">Disclaimer</Link>
		</nav>
		<div className="credit">
			<div
				className="logo"
				role="img"
				aria-label="Area 23 logo"
			/>
			<p>
				AM I SPOCK OR NOT est. 2003 by
			</p>
			<a href="https://area23.com/">Area 23</a>
		</div>
	</footer>
);

export default Footer;
