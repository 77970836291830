import React from 'react';

import './Header.css';

const Header = () => (
	<header className="Header">
		<h1>Am I <em className="spock">SPOCK</em> or <em className="not">NOT</em>?</h1>
	</header>
);

export default Header;
