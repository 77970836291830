import {
	navigate as reachNavigate,
	NavigateOptions,
} from '@reach/router';

export type LocationState = {
	/** Path we are navigating from */
	from?: string;
	/** Path to redirect to after action is completed */
	redirectTo?: string;
	/** Used by RouteNotFound for nested route 404 handling */
	notFound?: boolean;
	/** Used by useResetScroll to automatically scroll new routes to top */
	scrolled?: boolean;
	/** Information about the previous spock */
	previousSpock?: {
		/** Id of the spock that was rated */
		spockId: string;
		/** Rating given to that spock */
		rating: number;
		/** Randomly generated average rating for that spock */
		avgRating: number;
		/** Randomly generated vote count for that spock */
		voteCount: number;
	}
} | undefined;

export const navigate = (to: string, options?: NavigateOptions<NonNullable<LocationState>>) => (
	reachNavigate(to, options)
);
