import React, {
	FunctionComponent,
	useCallback,
	useRef,
	useState,
} from 'react';
import {
	Link,
	RouteComponentProps,
	useLocation,
} from '@reach/router';

import { useResetScroll } from '../helpers/useResetScroll';
import { LocationState, navigate } from '../helpers/navigation';
import spocks from '../data/spocks';
import './Rate.css';

const publicUrl = process.env.PUBLIC_URL;

interface Props extends RouteComponentProps {
	spockId?: string;
}

const Rate: FunctionComponent<Props> = ({
	location,
	spockId: spockIdProp,
}) => {
	useResetScroll();
	const formRef = useRef<HTMLFormElement | null>(null);
	const { origin } = useLocation();
	const getRandomSpock = useCallback(
		(last?: string) => {
			let newSpock: string;
			do {
				newSpock = spocks[Math.floor(Math.random() * spocks.length)]
			} while (newSpock === last);
			return newSpock;
		},
		[],
	);
	const [spockId, setSpockId] = useState(spockIdProp ?? getRandomSpock());
	const previousSpock = (location?.state as LocationState)?.previousSpock;
	const rateSpock = useCallback(
		(value: number) => {
			setSpockId(getRandomSpock());
			formRef.current?.reset();
			navigate(
				'/',
				{
					state: {
						previousSpock: {
							spockId,
							rating: value,
							avgRating: (Math.floor(Math.sqrt(Math.random()) * 901) + 100) / 100,
							voteCount: Math.floor(Math.random() * 4500000) + 100000,
						}
					},
				},
			);
		},
		[spockId, getRandomSpock],
	);
	let spockUrl = `${origin}/spocks/${spockId}`;
	return (
		<article className="Rate">
			{previousSpock && (
				<aside>
					<h1><em className="spock">SPOCK</em> or <em className="not">NOT</em></h1>
					<p className="others">What others thought</p>
					<p className="average">{previousSpock.avgRating}</p>
					<p>{previousSpock.voteCount.toLocaleString()} votes so far</p>
					<p className="rating">You rated Spock: {previousSpock.rating}</p>
					<img
						src={`${publicUrl}/spocks/${previousSpock.spockId}.jpg`}
						alt="Spock"
					/>
					<Link to={`/spocks/${previousSpock.spockId}`}>back to this spock</Link>
				</aside>
			)}
			<div className="spock">
				<section className="rate">
					<p>Please select a rating to see the next picture.</p>
					<form ref={formRef}>
						{[0, 1].map((g) => (
							<div
								className="ratingGroup"
								key={`ratingGroup${g}`}
							>
								{[1, 2, 3, 4, 5].map((i) => {
									const r = (g * 5) + i;
									return (
										<div
											className="rating"
											key={`rating${r}`}
										>
											<input
												id={`rating${r}`}
												type="radio"
												name="rating"
												value={r}
												onClick={() => rateSpock(r)}
											/>
											<label htmlFor={`rating${r}`}>
												{r}
											</label>
										</div>
									);
								})}
							</div>
						))}
					</form>
					<div className="rateRange">
						<p className="not">NOT</p>
						<div className="gradient" />
						<p className="spock">SPOCK</p>
					</div>
				</section>
				<div className="showMe">
					<p>Show me</p>
					<select>
						<option>Spock only</option>
						<option>Spock only</option>
						<option>Spock only</option>
					</select>
				</div>
				<img
					src={`${publicUrl}/spocks/${spockId}.jpg`}
					alt="Spock"
				/>
				<div className="share">
					<p>You can share this Spock with a friend:</p>
					<a href={spockUrl}>{spockUrl}</a>
				</div>
			</div>
		</article>
	);
};

export default Rate;
